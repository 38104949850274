<template>
    <v-row
        :class="{
            'd-flex justify-end my-3': user.id === userId,
            'd-flex justify-start my-3': user.id !== userId,
        }"
    >
        <div>
            <div class="d-flex ml-3 pl-3">
                <div class="d-flex flex-column pa-2 user-post">
                    <div class="d-flex flex-row">
                        <div :class="'d-flex justify-left mb-1'" v-if="user">
                            <!-- Avatar -->
                            <v-avatar size="36">
                                <v-img
                                    v-if="pictureURL"
                                    :src="pictureURL"
                                    :alt="user.name"
                                />
                                <v-icon v-else>mdi-account-circle</v-icon>
                            </v-avatar>
                            <!-- User name -->
                            <span
                                class="text-capitalize text-subtitle-2 mx-2 mt-2"
                                >{{ user.name }}</span
                            >
                        </div>
                        <v-spacer />
                        <div>
                            <p class="my-0 py-0 body-2" v-if="date">
                                {{ date.format('MMM DD, HH:mm') }}
                            </p>
                        </div>
                    </div>
                    <div v-if="!post.file" v-html="post.message"></div>
                    <div
                        v-else-if="
                            post.file.extension.toLowerCase() == 'pdf' ||
                                post.file.extension.toLowerCase() == 'PDF'
                        "
                        style="max-width: 300px"
                    >
                        <PdfThumbnail v-if="fileURL" :pdfFile="fileURL" />
                        <div v-else class="d-flex justify-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            />
                        </div>
                        <div styles="max-width: 200px !important">
                            <p class="text-truncate pt-2">
                                {{ fileName }}
                            </p>
                        </div>
                    </div>
                    <div
                        v-else-if="
                            post.file.extension.toLowerCase() != 'jpg' &&
                                post.file.extension.toLowerCase() != 'jpeg' &&
                                post.file.extension.toLowerCase() != 'png' &&
                                post.file.extension.toLowerCase() != 'webp'
                        "
                        class="my-2"
                    >
                        <v-chip label>
                            <v-icon class="mr-1">
                                mdi-paperclip
                            </v-icon>
                            <a
                                class="my-0"
                                target="_blank"
                                style="text-decoration: none"
                                :href="fileURL"
                                >{{ fileName }}
                            </a>
                        </v-chip>
                    </div>
                    <div v-else class="my-2">
                        <a v-if="fileURL" :href="fileURL" target="_blank">
                            <v-img
                                :src="fileURL"
                                :alt="fileName"
                                max-width="300"
                                max-height="388"
                            />
                        </a>
                        <div v-else class="d-flex justify-center">
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            />
                        </div>
                    </div>
                    <div
                        v-if="post.event && post.event.title"
                        class="mt-1 d-flex justify-left"
                    >
                        <v-btn
                            color="blue"
                            class="white--text text-capitalize"
                            outlined
                            @click="openEvent"
                        >
                            <v-icon dark left>mdi-calendar</v-icon>
                            {{
                                post.event.title.length > 32
                                    ? post.event.title.substring(0, 32) + '...'
                                    : post.event.title
                            }}
                        </v-btn>
                    </div>
                    <div v-if="post && post.files" class="d-flex flex-wrap">
                        <v-btn
                            color="grey lighten-3"
                            elevation="0"
                            class="mt-1 justify-left mr-1 text-capitalize grey--text text--darken-1  "
                            v-for="(file, i) in post.files"
                            :key="i"
                        >
                            <span @click="openDoc(file)"
                                ><v-icon>mdi-file</v-icon>{{ file }}</span
                            >
                        </v-btn>
                    </div>
                </div>
                <div class="degradedRightSide"></div>
            </div>
        </div>
    </v-row>
</template>

<script>
import API from '@/services/api'
import moment from 'moment'
import { openFile } from '@/services/storage/openFile'
import { loadImage } from '@/helpers/imageHandler'
import { mapMutations } from 'vuex'

export default {
    name: 'FollowUpPost',
    props: {
        post: {
            type: Object,
            required: true,
            default: () => {
                return {
                    message: '',
                    userId: '',
                }
            },
        },
        quoteId: {
            type: String,
        },
        showDate: {
            type: Boolean,
        },
        users: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    components: {
        PdfThumbnail: () => import('@/components/Chats/PdfThumbnail.vue'),
    },
    data: () => {
        return {
            userId: JSON.parse(localStorage.getItem('userId')),
            loading: false,
            errMessage: '',
            message: '',
            pictureURL: '',
            folderPictures: 'users_pictures',
            folderFollowUp: 'follow_up_documents',
            companyId: JSON.parse(localStorage.getItem('company')),
            date: null,
            user: {},
            fileName: undefined,
            fileURL: undefined,
        }
    },
    async mounted() {
        try {
            if (this.post.mentions) {
                for (const mention of this.post.mentions) {
                    this.post.message = this.post.message.replaceAll(
                        `@${mention.name}`,
                        `<span style="background-color: #e6f7ff; padding: 0 2px; border-radius: 4px;"><span style="color: #007bff; font-weight: bold;">@${mention.name}</span></span><span> </span>`
                    )
                }
            }
            this.date = moment.unix(this.post.createdOn.seconds)
            this.loading = true
            this.errMessage = ''
            // retrieve event
            if (this.post.eventId && !this.post.event) {
                this.post.event = await API.getEvent(this.post.eventId)
            }
            // replace message
            this.post.message = this.post.message
                ? this.post.message.replaceAll('\n\n', '<br>')
                : ''
            this.post.message = this.post.message
                ? this.post.message.replaceAll('<img', '<img width="100%"')
                : ''
            // set url in the user
            const index = this.users.findIndex(
                user => user.id == this.post.userId
            )
            if (index >= 0) {
                this.user = this.users[index]
                if (this.user.pictureURL) {
                    this.pictureURL = this.user.pictureURL
                }
            }
            // retrieve files
            this.retrieveFiles()
        } catch (error) {
            this.errMessage = error.message
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async retrieveFiles() {
            try {
                if (this.post.file) {
                    this.fileName = this.post.file.name.split('/').pop()
                    const aux = this.post.file.url.split('/')
                    aux.pop()
                    var path = aux.join('/')
                    setTimeout(() => {
                        loadImage(path, this.fileName)
                            .then(url => {
                                this.fileURL = url
                            })
                            .catch(error => {
                                console.log(error)
                            })
                    }, 3000)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openEvent() {
            this.$emit('openEvent', this.post.event, this.post.id)
        },
        async openDoc(file) {
            try {
                this.loading = true
                this.errMessage = ''
                const path = `${this.companyId}/${this.folderFollowUp}/${this.quoteId}`
                await openFile(file, path)
            } catch (error) {
                this.errMessage = error.message
                this.$emit('viewError', this.errMessage)
            } finally {
                this.loading = false
            }
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm')
        },
    },
}
</script>

<style lang="scss" scoped>
.user-post {
    width: auto;
    height: auto;
    background-color: #f5f5f5;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.other-post {
    width: auto;
    height: auto;
    background-color: #f5f5f5;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.scroll {
    overflow-x: scroll;
}

.degradedRightSide {
    overflow: hidden;
    height: 8px;
    width: 7px;
    position: relative;
    top: 0;
    bottom: auto;
    background: linear-gradient(-45deg, transparent 50%, #f5f5f5 50%);
}

.degradedLeftSide {
    overflow: hidden;
    height: 8px;
    width: 7px;
    position: relative;
    top: 0;
    bottom: auto;
    background: linear-gradient(45deg, transparent 50%, #f5f5f5 50%);
}
</style>
